$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
$page-body:#D5E3DD;
$black-lite:#1B2023;
$lite-gray:#F6F7F7;
$notify-text:#A0A0A0;
.notification-main {
    display: flex;
    align-items: center;
}

.notifications-sec {
    background-color: $boxbg;
    padding: 20px;
}

.unread-icon {
    background-color: $bgcolor;
    padding: 10px 16px;
    border-radius: 50%;
    color: $blue-lite;
}

.unread-icon1 {
    @extend .unread-icon;
    background-color: $blue-lite;
    color: $bgcolor;
}

.notification-text {
    font-size: 16px;
}

.notification-text1 {
    font-size: 16px;
    color: $notify-text;
}