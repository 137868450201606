$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
.login-box {
    background-color: $boxbg;
    padding: 50px 100px;
    .login-logo {
        width: 130px;
    }
    .cmn-text {
        font-size: 14px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 576px) {
    .login-box {
        padding: 30px 60px;
    }
}

@media only screen and (max-width: 425px) {
    .login-box {
        padding: 30px 30px;
    }
}