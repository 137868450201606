$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
$page-body:#D5E3DD;
$black-lite:#1B2023;
$lite-gray:#F6F7F7;
.post-body {
    background-color: $page-body;
    overflow-x: auto;
}

.base-note-sec {
    background-color: $boxbg;
    padding: 10px 10px 10px 30px !important;
    .base-note-head {
        background-color: $boxbg;
    }
}

.post-new-wrapper .page-content {
    padding-top: 35px !important;
}

.add-new-icon {
    background-color: $blue;
    padding: 7px;
    font-size: 15px;
    color: $boxbg
}

.add-replay-icon {
    font-size: 15px;
    padding-left: 10px;
}

.add-replay-icon-1 {
    @extend .add-replay-icon;
}

.add-replay-icon i {
    transform: rotate(-30deg);
}

.add-replay-icon-1 i {
    transform: rotate(30deg) scaleX(-1);
}

.base-right-text {
    font-size: 14px;
    color: $blue;
    font-weight: 500;
    padding-right: 20px;
}

@media only screen and (max-width: 768px) {
    .base-note-sec {
        padding: 10px 10px 10px 48px !important;
    }
    .base-right-text {
        padding-right: 10px;
    }
}

.sidenav {
    height: 100%;
    width: 100%;
    background-color: $boxbg;
    overflow-x: auto;
    padding-top: 10px;
}

.dropdown-btn {
    padding: 12px 8px 6px 16px;
    text-decoration: none;
    font-size: 18px;
    color: $black-lite;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.sidenav a {
    @extend .dropdown-btn;
    font-size: 14px;
}

.actives {
    background-color: transparent;
}


/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */

.dropdown-container {
    display: none;
    padding-left: 8px;
    font-size: 14px;
}


/* Optional: Style the caret down icon */

.fa-caret-down {
    float: right;
    padding-right: 8px;
}


/* Some media queries for responsiveness */

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

@media screen and (max-height: 450px) {
    .base-note-sec-1 {
        padding: 1px 2px 0px 2px !important;
    }
}

.base-note-sec-1 {
    padding: 1px 0px 0px 30px !important;
}

hr {
    margin: 0.5rem 0;
}

@media only screen and (max-width: 992px) {
    .sidenav {
        background-color: $boxbg;
        overflow-x: hidden;
        padding-top: 10px;
        width: auto;
        height: auto;
    }
}

.sidenav-inner-sec {
    text-decoration: underline !important;
    color: $blue !important;
    font-size: 14px !important;
    font-weight: 500;
    padding: 3px;
}

#picture__input {
    display: none;
}

.picture {
    width: 200px;
    aspect-ratio: 16/9;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
}

.picture:hover {
    color: #777;
    background: #ccc;
}

.picture:active {
    border-color: turquoise;
    color: turquoise;
    background: #eee;
}

.picture:focus {
    color: #777;
    background: #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.picture__img {
    max-width: 100%;
}

.modal-xl {
    width: 100%;
}

.modal-upload-tab {
    color: $black-lite !important;
    font-weight: 500;
}

.modal-header {
    border-bottom: none;
}

.modal-tab-sec {
    background-color: $boxbg !important;
}

.min-vh-90 {
    min-height: 75vh;
}

.min-vh-90 input[type=file] {
    opacity: 0;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.modal-right-box {
    background-color: $lite-gray;
}

.img-area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.single-img {
    padding: 0 5px 10px;
}

.wrapper-img {
    width: 149px;
}

.note-btn {
    border: none !important;
    background-color: transparent !important;
    color: $boxbg !important;
}

.note-btn-group ::after {
    display: none !important;
}

.note-toolbar {
    background-color: $black-lite !important;
}

.note-resizebar {
    display: none !important;
}

.note-editor.note-airframe .note-status-output,
.note-editor.note-frame .note-status-output {
    border: none !important;
}

.note-editable {
    height: 300px;
}

input[type="checkbox"][id^="myCheckbox"] {
    display: none;
}

.image-select {
    border: 1px solid #fff;
    display: block;
    position: relative;
    cursor: pointer;
}

.image-select:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border: 1px solid $blue;
    position: absolute;
    top: -9px;
    right: -11px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
    z-index: 3;
}

.image-select img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

 :checked+.image-select {
    border-color: $blue;
    border: 5px solid $blue;
}

 :checked+.image-select:before {
    content: "✓";
    background-color: $blue;
    transform: scale(1);
}

 :checked+.image-select img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
}

.attachment-img {
    width: 100px;
}

.click-img-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    margin-bottom: 0 !important;
}

#sectionhide,
#sectionhide1 {
    display: block;
}

#publishhide,
#publishhide1 {
    display: none;
}

.publish-sec {
    padding: 18px;
}

.publish-icon {
    font-size: 22px;
    padding-right: 1px;
}

.new-publish {
    float: right;
}

.publish-now {
    @extend .new-publish;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    color: $blue;
    padding: 0;
    display: inherit;
    cursor: pointer;
}

.time-publish {
    padding: 6px 15px;
    border: 1px solid $blue;
    margin-right: 10px;
}

.ui-datepicker-header {
    color: $blue;
    text-align: center;
}

.ui-datepicker-prev span,
.ui-datepicker-next span {
    display: none;
}

.ui-datepicker-prev:after {
    content: "<";
    font-size: 1.2rem;
    float: left;
    margin-left: 20px;
    cursor: pointer;
    color: $blue;
}

.ui-datepicker-next:after {
    content: ">";
    float: right;
    font-size: 1.2rem;
    margin-right: 20px;
    cursor: pointer;
    color: $blue;
}

.ui-datepicker-calendar th {
    padding: 4px;
    color: #707070;
    font-size: 14px;
    font-weight: 400;
}

.ui-datepicker-calendar {
    text-align: center;
    margin: 0 auto;
    padding: 8px;
}

.ui-datepicker-month {
    font-weight: 700;
    color: $black-lite;
}

.ui-datepicker-title {
    padding: 0px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
}

.ui-datepicker-calendar td {
    padding: 4px 0px;
}

.ui-datepicker-calendar .ui-state-default {
    text-decoration: none;
    color: black;
    font-weight: 500;
    font-size: 12px;
    padding: 0;
}

.ui-datepicker-calendar .ui-state-active {
    color: #fff;
    background: $blue;
    border-radius: 25px;
    padding: 6px;
}

#calendar a {
    font-size: 12px;
    text-align: unset;
}

#calendar .ui-datepicker-next {
    padding: 0 !important;
}

#calendar .ui-datepicker-prev {
    padding: 0 !important;
}