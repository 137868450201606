@import "./font/style.css";
@import "./bootstrap.min.css";
@import "./custom/add-new-post";
@import "./custom/button";
@import "./custom/chat-room";
@import "./custom/collapse";
// @import "./custom/common";
@import "./custom/form";
@import "./custom/header";
@import "./custom/layout";
@import "./custom/login";
@import "./custom/notification";
@import "./custom/sectors";
@import "./custom/sidebar";
@import "./custom/style";
@import "./custom/table";