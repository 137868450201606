$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
$page-body:#D5E3DD;
.body-content-text {
    font-size: 22px;
    font-weight: 600;
    line-height: 0;
}

.body-info-text {
    font-size: 14px;
    font-weight: 700;
}

.body-counter,
.body-text-arrow {
    @extend .body-info-text;
    margin-inline: 2px;
}

.body-info-text1 {
    @extend .body-info-text;
    color: $blue
}

.password-card {
    background-color: $boxbg;
    border: 1px solid $password-sec;
    .password-text-box {
        padding: 10px 20px;
    }
    .password-text {
        color: #000;
        font-weight: 600;
    }
    .password-meter {
        background-color: $password-sec;
        text-align: center;
        padding: 6px;
        @extend .password-text;
    }
}

.text-right {
    text-align: right;
}

@media only screen and (max-width: 992px) {
    .text-right {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .dis-none {
        display: contents !important;
    }
    .dispaly-web {
        display: none;
    }
}

.table-align-sec {
    display: flex;
}

.dis-none {
    display: none;
}

.padding-right {
    padding-right: 0 !important;
}