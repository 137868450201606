$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a,
button {
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    background-color: $bgcolor;
    font-family: 'Montserrat', sans-serif;
}

.no-padding-left {
    padding-left: 0 !important;
}

#dashboard_card {
    .card {
        border-radius: 5px !important;
        border-top: 5px solid #0d6efd;
    }
}

#chartCard .card{
    .card-body {
        height: 100%;
    }
    .pie {
        height: 100%;
        max-height: 290px;
        min-height: 250px;
    }
}

.media-button{
    display: none;
}

.media-section-tab:hover .media-button {
    display: block;
  }