$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
.bg-light {
    background-color: $boxbg !important;
    z-index: 1000;
}

.sidebar-dark {
    background-color: $sidebar;
    width: 225px;
}

.dropmenu {
    margin-left: -25px !important;
}

.dropdown-menu-dark {
    background-color: $sidebar;
    font-size: 14px;
    font-weight: 500;
}

.dropdown-active {
    font-weight: 600
}

.dropdown-menu-dark .dropdown-item {
    color: $boxbg;
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
    color: $boxbg;
    background-color: $blue;
}

@media only screen and (max-width: 992px) {
    .dropmenu {
        margin-left: 0px !important;
    }
}

.btn-dark {
    color: #000;
    border-color: #ffffff;
    background-color: transparent;
    font-size: 22px;
}

.home-icon {
    margin-left: 40px;
    font-size: 18px;
}

.notification-icon {
    margin-right: 15px;
}

.notification-badge {
    position: absolute;
    top: 1.9em;
    // right: 16.4em;
    width: 0.8em;
    height: 0.8em;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.6em;
}