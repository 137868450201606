$delete:#FF0000;
$colors:( red:#FD3434, green:#1A7C53, violet:#703DA2, rose:#C62E91, acqua:#2EC6A9, Yellow-Green:#80C62E, orange:#FF8900, yellow:#FFE200, gray:#E2E2E2, blue:#2E3AC6,pink:pink,purple:purple,brown:brown,darkBlue:darkBlue,lightBlue:lightBlue,magenta:magenta);
input[type="radio"] {
    display: none;
    &:checked+.color-picker {
        span {
            transform: scale(1.25);
        }
        @each $name,
        $value in $colors {
            .#{$name} {
                border: 2px solid darken($value, 25%);
            }
        } // !@each
    } // !&:checked + label
}

.color-picker {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        span {
            transform: scale(1.25);
        }
    }
    span {
        display: block;
        width: 100%;
        height: 100%;
        transition: transform .2s ease-in-out;
        @each $name,
        $value in $colors {
            &.#{$name} {
                background: $value;
            }
        }
    }
}

.opacity-clr {
    opacity: .3;
}

.sector-delete {
    color: $delete;
    font-size: 12px;
}