$bgcolor: #eeeeef;
$boxbg: #fff;
$sidebar: #1e2b4c;
$blue: #195890;
$blue-lite: #273c6f;
$brown: #707070;
$password-sec: #b6e0bc;
$table-clr: #c3c3c6;
$red: #ff0000;
$black: #000;
$lite-red: #ffe2e2;
$lite-orange: #fff2e2;
$lite-yellow: #fffce2;
$lite-green: #e9f6f2;
$table-even: #f4f6f6;
.tables {
  background-color: $boxbg;
  border: 1px solid $table-clr;
  font-size: 14px;
  margin-top: 15px;
}

.user-table tr {
  border-style: none !important;
  border: 1px solid $boxbg;
}

.user-table tr:last-child {
  border: 1px solid $table-clr;
}

.tables .accordion-xs-toggle:first-child {
  font-weight: 600;
  color: $blue;
}

.user-table .td:nth-child(1) {
  color: $blue;
}

.user-table .td:nth-child(5) {
  color: $blue;
}

.tables > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-clr;
}

.check-sec {
  margin-right: 10px;
}

.section-sec {
  display: inline-block;
  margin-left: 15px;
}

.section-sec-1 {
  display: inline-block;
  margin-left: 5px;
}

.table-text {
  font-size: 10px;
  color: $blue;
  font-weight: 500;
  margin-inline: 2px;
}

.table-text1 {
  @extend .table-text;
  color: $red;
}

.table-text-2 {
  font-size: 14px;
  font-weight: 500;
  color: $blue;
}

.table-text-sec {
  visibility: hidden;
}

.tr:nth-child(odd) {
  background-color: $table-even;
}

.banker-table .tr:nth-child(odd) {
  background-color: $boxbg;
}

.user-table .tr:hover .table-text-sec {
  visibility: visible;
}

.post-table .tr:hover .table-text-sec {
  visibility: visible;
}

.sector-table .tr:hover .table-text-sec {
  visibility: visible;
}

.role-table .tr:hover .table-text-sec {
  visibility: visible;
}

.subscribers-table .tr:hover .table-text-sec {
  visibility: visible;
}

.topics-table .tr:hover .table-text-sec {
  visibility: visible;
}

.tag-table .tr:hover .table-text-sec {
  visibility: visible;
}

@media only screen and (max-width: 992px) {
  .section-sec {
    display: block;
    margin-top: 10px;
    margin-left: 0px !important;
  }
  .section-sec-1 {
    display: inline-block;
    margin-top: 10px;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .table-text-sec {
    visibility: visible !important;
  }
  .table-text {
    padding-right: 8px;
  }
}

.post-table td:first-child {
  font-weight: 600;
  color: $blue;
}

.post-table td {
  color: $blue;
}

.table-inner-text {
  color: $black;
}

.sectors-table td:first-child {
  font-weight: 600;
  color: $blue;
}

.sectors-table td {
  color: $blue;
}

.topics-table td:first-child {
  font-weight: 600;
  color: $blue;
}

.topics-table td {
  color: $blue;
}

.tag-table td:first-child {
  font-weight: 600;
  color: $blue;
}

.tag-table td {
  color: $blue;
}

.subscribers-col-red {
  background-color: $lite-red !important;
}

.subscribers-col-orange {
  background-color: $lite-orange !important;
}

.subscribers-col-yellow {
  background-color: $lite-yellow !important;
}

.subscribers-col-green {
  background-color: $lite-green !important;
}

.divtable .tr {
  overflow: hidden;
  clear: both;
  border-bottom: none;
}

.table-head {
  border-bottom: 1px solid #ccc !important;
}

.divtable .th,
.divtable .td {
  padding: 5px 10px;
  float: left;
}

.divtable .th {
  font-weight: bold;
}

.user-table .username {
  width: 30%;
}

.user-table .name {
  width: 10%;
}

.user-table .email {
  width: 20%;
}

.user-table .location {
  width: 10%;
}

.user-table .role {
  width: 20%;
}

.user-table .post {
  width: 10%;
}

.tag-table .name {
  width: 70%;
}

.tag-table .count {
  width: 30%;
  color: $blue-lite;
  font-weight: 500;
}

.post-table .username {
  width: 30%;
}

.post-table .author {
  width: 10%;
}

.post-table .topics {
  width: 10%;
}

.post-table .sector {
  width: 10%;
}

.post-table .tags {
  width: 20%;
}

.post-table .date {
  width: 20%;
  color: $black !important;
}

.role-table .roles {
  width: 25%;
}

.role-table .user {
  width: 25%;
}
.role-table .topic {
  width: 25%;
}

.role-table .content {
  width: 25%;
}

.banker-table .name,
.subscribers-table .name {
  width: 10%;
}

.banker-table .package,
.subscribers-table .package {
  width: 10%;
}

.banker-table .sector,
.subscribers-table .sector {
  width: 10%;
}

.banker-table .email,
.subscribers-table .email {
  width: 20%;
}

.banker-table .gender,
.subscribers-table .gender {
  width: 10%;
}

.banker-table .age,
.subscribers-table .age {
  width: 10%;
  color: $black !important;
}

.banker-table .colour,
.subscribers-table .colour {
  width: 10%;
}
.banker-table .created-date,
.subscribers-table .created-date {
  width: 20%;
}
// For Order
.order-table .order-id {
  width: 20%;
}

.order-table .amount {
  width: 10%;
}

.order-table .payment-status {
  width: 10%;
}

.order-table .billing-name {
  width: 10%;
}

.order-table .billing-city {
  width: 10%;
}

.order-table .transaction-date {
  width: 20%;
}

.order-table .date {
  width: 20%;
}

// For Money wallet ref code table
.moneyWalletRefCodes-table .code-prefix-suffix {
  width: 20%;
}

.moneyWalletRefCodes-table .code {
  width: 30%;
}

.moneyWalletRefCodes-table .used {
  width: 10%;
}
.moneyWalletRefCodes-table .user-count {
  width: 10%;
}

.moneyWalletRefCodes-table .highest-level {
  width: 10%;
}

.moneyWalletRefCodes-table .date {
  width: 20%;
}

// for comment
.comment-table .name {
  width: 20%;
}

.comment-table .comment {
  width: 70%;
}

.sector-table .name {
  width: 33%;
}
.sector-table .count {
  width: 33%;
}
.sector-table .colour {
  width: 33%;
}
.topics-table .politics {
  width: 50%;
}
.topics-table .colour {
  width: 50%;
}
.headings {
  background-color: $boxbg !important;
}

@media (min-width: 767px) {
  .accordion-xs-collapse.inner-sec.collapse {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .accordion-xs .headings {
    display: none;
  }
  .accordion-xs .tr {
    border-bottom-color: #fff;
  }
  .accordion-xs .th,
  .accordion-xs .td {
    float: none;
    width: auto;
    padding: 0;
  }
  .accordion-xs .accordion-xs-toggle {
    color: #fff;
    padding: 10px;
    cursor: pointer;
  }
  .accordion-xs .accordion-xs-toggle:after {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    top: -16px;
    right: 10px;
    position: relative;
  }
  .banker-table .accordion-xs .accordion-xs-toggle:after {
    font: var(--fa-font-solid);
    content: " \f0d7";
    float: right;
  }
  .banker-table .accordion-xs .accordion-xs-toggle:after {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    top: 0px;
    right: 10px;
    position: relative;
  }
  .accordion-xs .accordion-xs-toggle:after {
    font: var(--fa-font-solid);
    content: " \f0d7";
    float: right;
  }

  .accordion-xs-collapse .inner {
    padding: 10px 33px;
  }
  .accordion-xs-collapse .td:before {
    font-weight: 400;
    color: $black !important;
    font-size: 12px;
  }
  .user-table .accordion-xs-collapse .name:before {
    content: "Name: ";
  }
  .user-table .accordion-xs-collapse .email:before {
    content: "Email: ";
  }
  .user-table .accordion-xs-collapse .location:before {
    content: "Location: ";
  }
  .user-table .accordion-xs-collapse .role:before {
    content: "Role: ";
  }
  .user-table .accordion-xs-collapse .post:before {
    content: "Posts: ";
  }
  .table-media {
    display: none;
  }
  .tr.table-media1 {
    display: contents !important;
  }
  .top-table-title {
    padding: 9px !important;
    font-size: 14px;
    border-bottom: 1px solid $table-clr;
    font-weight: 600;
  }
  .title-check {
    zoom: 1;
    margin-right: 6px;
  }
  .inner-sec .td {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: $blue-lite !important;
    font-size: 12px;
  }
  .inner-sec .inner {
    display: grid;
    grid-gap: 0.5rem;
  }
}

.tr.table-media1 {
  display: none;
}

@media (max-width: 767px) {
  .tag-table .accordion-xs-collapse .count:before {
    content: "Count: ";
  }
  .post-table .accordion-xs-collapse .author:before {
    content: "Author: ";
  }
  .post-table .accordion-xs-collapse .topics:before {
    content: "Topics: ";
  }
  .post-table .accordion-xs-collapse .sector:before {
    content: "Sector: ";
  }
  .post-table .accordion-xs-collapse .tags:before {
    content: "Tags: ";
  }
  .post-table .accordion-xs-collapse .date:before {
    content: "Date: ";
  }
  .role-table .accordion-xs-collapse .topic:before {
    content: "Topic Management: ";
  }
  .role-table .accordion-xs-collapse .content:before {
    content: "Content Management: ";
  }
  .subscribers-table .accordion-xs-collapse .package:before {
    content: "Package: ";
  }
  .subscribers-table .accordion-xs-collapse .sector:before {
    content: "Sector: ";
  }
  .subscribers-table .accordion-xs-collapse .email:before {
    content: "Email: ";
  }
  .subscribers-table .accordion-xs-collapse .gender:before {
    content: "Gender: ";
  }
  .subscribers-table .accordion-xs-collapse .age:before {
    content: "Age: ";
  }
  .subscribers-table .accordion-xs-collapse .colour:before {
    content: "Colour: ";
  }
  .subscribers-table .accordion-xs-collapse .created-date:before {
    content: "Created Date: ";
  }
  .sector-table .accordion-xs-collapse .count:before {
    content: "Count: ";
  }
  .sector-table .accordion-xs-collapse .colour:before {
    content: "Colour: ";
  }
  .topics-table .accordion-xs-collapse .count:before {
    content: "Count: ";
  }
  .banker-table .accordion-xs-collapse .package:before {
    content: "Package: ";
  }
  .banker-table .accordion-xs-collapse .sector:before {
    content: "Sector: ";
  }
  .banker-table .accordion-xs-collapse .email:before {
    content: "Email: ";
  }
  .banker-table .accordion-xs-collapse .gender:before {
    content: "Gender: ";
  }
  .banker-table .accordion-xs-collapse .age:before {
    content: "Age: ";
  }
  .banker-table .accordion-xs-collapse .colour:before {
    content: "Colour: ";
  }
  .banker-table .accordion-xs-collapse .created-date:before {
    content: "Created Date: ";
  }
  // for order
  .order-table .accordion-xs-collapse .order-id:before {
    content: "Order ID: ";
  }
  .order-table .accordion-xs-collapse .amount:before {
    content: "Amount: ";
  }
  .order-table .accordion-xs-collapse .payment-status:before {
    content: "Payment Status: ";
  }
  .order-table .accordion-xs-collapse .billing-name:before {
    content: "Billing Name: ";
  }
  .order-table .accordion-xs-collapse .billing-city:before {
    content: "Billing City: ";
  }
  .order-table .accordion-xs-collapse .transaction-date:before {
    content: "Transaction Date: ";
  }
  .order-table .accordion-xs-collapse .date:before {
    content: "Date: ";
  }

  // For For Money wallet ref codes
  .moneyWalletRefCodes-table .accordion-xs-collapse .code-prefix-suffix:before {
    content: "Prefix,Suffix: ";
  }
  .moneyWalletRefCodes-table .accordion-xs-collapse .code:before {
    content: "Referral Code: ";
  }
  .moneyWalletRefCodes-table .accordion-xs-collapse .used:before {
    content: "Is Used: ";
  }
  .moneyWalletRefCodes-table .accordion-xs-collapse .user-count:before {
    content: "User Count: ";
  }
  .moneyWalletRefCodes-table .accordion-xs-collapse .highest-level:before {
    content: "Highest Level: ";
  }
  .moneyWalletRefCodes-table .accordion-xs-collapse .date:before {
    content: "Created At: ";
  }

  // for comment
  .comment-table .accordion-xs-collapse .name:before {
    content: "User: ";
  }
  .comment-table .accordion-xs-collapse .comment:before {
    content: "Comment: ";
  }
}

.post-table .inner .td {
  color: $blue-lite;
  font-weight: 500;
}

.banker-table,
.subscribers-table .inner .td {
  color: $blue-lite;
  font-weight: 500;
}
.role-table .user {
  width: 33%;
}
.role-table .roles {
  width: 33%;
}
.role-table .topic {
  width: 33%;
}
.role-table .content {
  width: 30%;
}

.table-area-data{
  color:$blue;
  white-space:normal;
}