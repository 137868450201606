$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
$black:#000;
$chat-box:#CDCFDD;
$dot-box:#5494F3;
.chat-card {
    background-color: $chat-box;
    border-radius: 8px;
}

.chat-group {
    font-size: 17px;
    font-weight: 700;
}

.chat-date {
    font-size: 13px;
    white-space: nowrap;
}

.custom-menu {
    position: relative;
    display: inline-block;
}

.custom-menu-dots {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.custom-menu-items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: $boxbg;
    border: 1px solid $black;
    padding: 0;
    margin-left: -70px;
}

.custom-menu-items li {
    list-style: none;
    padding: 5px 30px 5px 10px;
    cursor: pointer;
}

.custom-menu-items li:hover {
    background-color: $dot-box;
    color: $boxbg;
    font-weight: 600;
}

.chat-card-inner {
    background-color: $blue-lite;
    padding: 5px 3px 5px 24px;
    font-size: 14px;
    color: $boxbg;
    font-weight: 500;
    border-radius: 0 0 8px 8px;
}

.chat-group-members {
    font-size: 36px;
    font-weight: 700;
    color: $sidebar;
}

.chat-text {
    font-size: 18px;
    color: $sidebar;
    font-weight: 700;
    padding-left: 8px;
}

.chat-card a:hover {
    color: $black;
}

@media only screen and (max-width: 992px) {
    .btn-media {
        display: block;
        margin-left: 0px !important;
        margin-top: 20px;
    }
}