$bgcolor: #eeeeef;
$boxbg: #fff;
$sidebar: #1e2b4c;
$blue: #195890;
$blue-lite: #273c6f;
$brown: #707070;
$password-sec: #b6e0bc;
$table-clr: #c3c3c6;
$red: #ff0000;

.page-wrapper.toggled .sidebar-wrapper1 {
    left: 0px;
    padding-top: 60px;
}

.sidebar-wrapper1 {
    width: 67px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}

.sidebar-wrapper1 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper1 a {
    text-decoration: none;
}
/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper1 .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.sidebar-wrapper1 .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}

.sidebar-wrapper1 .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper1 .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper1 .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper1 .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper1 .sidebar-menu {
    padding-bottom: 10px;
}

.sidebar-wrapper1 .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}

.sidebar-wrapper1 .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 30px 8px 20px;
    font-weight: 600;
}

.sidebar-wrapper1 .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    color: #9aa0a4;
}

.sidebar-wrapper1 .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}

.sidebar-wrapper1 .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
}

.sidebar-wrapper1 .sidebar-menu ul li a span.label,
.sidebar-wrapper1 .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}

.sidebar-wrapper1 .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper1 .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}

.sidebar-wrapper1 .sidebar-menu .sidebar-submenu {
    display: none;
}

.sidebar-wrapper1 .sidebar-menu .sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 17px;
}
.chiller-theme .sidebar-wrapper1 {
    background-color: $sidebar;
}

.sidebar-single-menu {
    font-weight: 600 !important;
}

.chiller-theme .sidebar-wrapper1 .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper1 .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper1 .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper1 .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper1 .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper1 .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper1 .sidebar-brand > a,
.chiller-theme .sidebar-wrapper1 .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
    color: $boxbg;
    font-weight: 400;
}

.chiller-theme .sidebar-wrapper1 .sidebar-menu ul li:hover > a,
.chiller-theme .sidebar-wrapper1 .sidebar-menu .sidebar-dropdown.active > a,
.chiller-theme .sidebar-wrapper1 .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper1 .sidebar-brand > a:hover,
.chiller-theme .sidebar-footer > a:hover i {
    color: $boxbg;
}



.chiller-theme .sidebar-wrapper1 ul li:hover a i,
.chiller-theme .sidebar-wrapper1 .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper1 .sidebar-search input.search-menu:focus + span,
.chiller-theme .sidebar-wrapper1 .sidebar-menu .sidebar-dropdown.active a i {
    color: $boxbg;
}
.page-wrapper.toggled .page-content1 {
    padding-left: 75px;
    padding-top: 80px;
}

.sidemenu_items1 {
    display: none;
}
.collapse_icon {
    transform: rotate(90deg) !important;
}
.sidebar-dropdown1:hover {
    background-color: $blue;
}
.sidebar-dropdown1:hover a:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 18px;
    left: 52px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $bgcolor;
    background-color: transparent;
    transform: rotate(89deg);
}
.page-wrapper.toggled .post-content {
    padding-left: 200px;
    padding-top: 60px !important;

}
@media screen and (min-width: 768px) {
    .page-wrapper.toggled .post-content {
        padding-left: 65px;
        padding-top: 35px !important;
    }
}