$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
.button {
    border: 1px solid $boxbg;
    background-color: $blue;
    color: $boxbg;
    padding: 8px 28px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    border-radius: 2px;
}

.publish-button {
    @extend .button;
    padding: 6px 15px;
    border: 1px solid $blue;
}

.notification-button {
    @extend .button;
    padding: 4px 40px;
    border: 1px solid $blue;
}

.button-1 {
    @extend .button;
    display: block;
    width: 100%;
    background-color: $sidebar;
}

.button-2 {
    @extend .button;
    display: block;
    padding: 10px 10px;
}

.wrapper-button {
    @extend .button;
    background-color: transparent;
    padding: 6px 15px;
    color: $blue;
    font-size: 12px;
    border: 2px solid $blue;
}

.wrapper-button-1 {
    @extend .wrapper-button;
    padding: 6px 10px;
}

.notification-read {
    @extend .wrapper-button;
    padding: 4px 30px;
}

.modal-upload-button {
    @extend .wrapper-button;
    padding: 8px 28px;
}

.btn-search {
    margin-left: 4px !important;
    border: 1px solid $brown;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 12px;
    background-color: $boxbg;
}

.trash-button {
    @extend .button;
    background-color: transparent;
    border: 1px solid $red;
    color: $red;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 12px 28px;
}