$bgcolor:#EEEEEF;
$boxbg:#fff;
$sidebar:#1E2B4C;
$blue:#195890;
$blue-lite:#273C6F;
$brown:#707070;
$password-sec:#B6E0BC;
$table-clr:#C3C3C6;
$red:#FF0000;
$check:#1B2023;
$select-box:#E5E0E5;
$select-brown:#9AA0A4;
$edit-box:#CDCFDD;
.form-check-label {
    font-size: 14px;
    // to remove overlapping of checkbox and label
    overflow-wrap: anywhere;
    margin-right: 2rem;
}

.form-label-head {
    font-size: 18px;
    font-weight: 600;
}

.form-control {
    font-size: 14px;
}

.edit-subscriber {
    background-color: $edit-box !important;
}

.form-label {
    font-size: 14px;
    font-weight: 700;
}

.form-label-1 {
    font-size: 12px;
    font-weight: 500;
}

.form-label-2 {
    font-size: 14px;
    font-weight: 500;
}

.form-control {
    border: 1px solid $brown;
}

.select-box {
    width: 190px;
    display: inline;
    font-size: 14px;
    font-weight: 500;
    @extend .form-control;
}

.select-box-1 {
    font-weight: 500;
    border: 1px solid $brown;
}

.select-box-2 {
    @extend .select-box;
    width: 210px !important;
    height: 32px;
    background-color: $boxbg;
}

.select-box-3 {
    @extend .select-box;
    width: 150px !important;
}

.select-box-4 {
    @extend .select-box;
    width: 150px !important;
    margin-top: 2px;
    margin-bottom: 2px;
}

.select-box-5 {
    @extend .select-box;
    width: 100px !important;
}

.sec-group {
    width: 320px;
    float: right;
}

@media only screen and (max-width: 992px) {
    .sec-group {
        float: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .sec-group {
        width: 275px !important;
        float: left !important;
    }
}

.check-box {
    width: 1.5em;
    height: 1.5em;
    border: 1px solid $check;
}

.form-check-input:checked {
    background-color: $blue;
    border-color: $blue;
}

.dropdown-menu {
    background-color: $select-box;
    min-width: 13.78rem;
    border: 1px solid $select-brown;
}

.form-check {
    font-size: 16px;
    font-weight: 600;
    .select-check-text {
        float: left !important;
        font-size: 12px;
    }
    .select-check-box {
        float: right !important;
        border: 1px solid $check;
    }
}

.select-check-text-1 {
    font-size: 14px;
    float: left !important;
    font-weight: 500;
}

.form-check-sec {
    padding-left: 0 !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: $check;
    text-decoration: none;
    background-color: transparent !important;
}