$bgcolor: #eeeeef;
$boxbg: #fff;
$sidebar: #1e2b4c;
$blue: #195890;
$blue-lite: #273c6f;
$brown: #707070;
$password-sec: #b6e0bc;
$table-clr: #c3c3c6;
$red: #ff0000;
.page-wrapper {
    height: 100vh;
}

.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}

.page-wrapper .theme.chiller-theme {
    background: $sidebar;
}


/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
    padding-top: 60px;
}

@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 200px;
        padding-top: 60px;
    }
}


/*----------------show sidebar button----------------*/

#show-sidebar {
    position: fixed;
    left: 5px;
    top: 5px;
    border-radius: 0 4px 4px 0px;
    width: 35px;
    transition-delay: 0.3s;
}

.page-wrapper.toggled #show-sidebar {
    left: -40px;
}


/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
    width: 220px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}


/*----------------sidebar-content----------------*/

.sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    position: relative;
}

.sidebar-content.desktop {
    overflow-y: hidden;
}


/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.sidebar-wrapper .sidebar-brand>a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}


/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}


/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 30px 8px 20px;
    font-weight: 600;
}

.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    color: #9aa0a4;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown a p {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
    visibility: hidden;
    height: 0px !important;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu.show {
    height: fit-content !important;
    visibility: visible;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
    transform: rotate(90deg);
    right: 17px;
}


/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    padding-top: 55px;
}

.page-wrapper .page-content>div {
    padding: 20px 40px 100px 40px;
}

.page-wrapper .page-content {
    overflow-x: hidden;
}


/*------scroll bar---------------------*/

::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: $brown;
    border: 0px none $boxbg;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    background: $brown;
}

::-webkit-scrollbar-thumb:active {
    background: $brown;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none $boxbg;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: transparent;
}

::-webkit-scrollbar-track:active {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background-color: $sidebar;
}

.sidebar-single-menu {
    font-weight: 600 !important;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: $boxbg;
    font-weight: 400;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: $boxbg;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: $boxbg;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: $boxbg;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: $boxbg;
}

.sidebar-dropdown:hover {
    background-color: $blue;
}

.sidebar-submenu {
    background-color: $blue-lite;
}

.active {
    background-color: $blue;
}

.sidebar-dropdown:hover a:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 18px;
    left: 204px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $bgcolor;
    background-color: transparent;
    transform: rotate(89deg);
}

.sidebar-brand {
    visibility: hidden;
}

@media only screen and (max-width: 768px) {
    .sidebar-brand {
        visibility: visible;
    }
    .navbar-brand .img-fluid {
        display: none;
    }
    .mobile-icon {
        display: contents !important;
    }
}

@media only screen and (max-width: 576px) {
    .page-wrapper .page-content>div {
        padding: 0px 10px;
    }
    .base-note-sec-1 {
        padding: 1px 0px 0px 2px !important;
    }
}

.mobile-icon {
    display: none;
}

.fa-circle-chevron-up {
    transform: rotate(-90deg);
}

.sidemenu_items {
    display: initial;
    font-weight: 600;
    font-size: 14px;
}